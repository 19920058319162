/**
 * Import JS knihoven
 */
import anchor from '@sportisimo/old_anchor/src/index';
import applepay from '@sportisimo/old_applepay/src/index';
import autocomplete from '@sportisimo/old_autocomplete/src/index';
import banner from '@sportisimo/old_banner/src/index';
import bg from '@sportisimo/old_bg/src/index';
import checkbox from '@sportisimo/old_checkbox/src/index';
import click from '@sportisimo/old_click/src/index';
import countdown from '@sportisimo/old_countdown/src/index';
import form from '@sportisimo/old_form/src/index';
import geolocate from '@sportisimo/old_geolocate/src/index';
import gtm from '@sportisimo/old_gtm/src/index';
import height from '@sportisimo/old_height/src/index';
import input from '@sportisimo/old_input/src/index';
import image from '@sportisimo/old_image/src/index';
import imagerotator from '@sportisimo/old_image-rotator/src/index';
import loading from '@sportisimo/old_loading/src/index';
import link from '@sportisimo/old_link/src/index';
import menu from '@sportisimo/old_menu/src/index';
import overlay from '@sportisimo/old_overlay/src/index';
import productlist from '@sportisimo/old_product-list/src/index';
import recombee from '@sportisimo/recombee/src/index';
import robotcheck from '@sportisimo/old_robotcheck/src/index';
import rwd from '@sportisimo/old_rwd/src/index';
import scroll from '@sportisimo/old_scroll/src/index';
import serviceworker from '@sportisimo/old_service-worker/src/index';
import share from '@sportisimo/old_share/src/index';
import slider from '@sportisimo/old_slider/src/index';
import sound from '@sportisimo/old_sound/src/index';
import touch from '@sportisimo/old_touch/src/index';
import upload from '@sportisimo/old_upload/src/index';
import url from '@sportisimo/old_url/src/index';
import valueplusminus from '@sportisimo/old_value-plus-minus/src/index';
import valueselect from '@sportisimo/old_value-select/src/index';
import valueslider from '@sportisimo/old_value-slider/src/index';
import valuetoggle from '@sportisimo/old_value-toggle/src/index';
import viewport from '@sportisimo/old_viewport/src/index';
import yvideo from '@sportisimo/old_yvideo/src/index';

/**
 * Import TS core facades
 */
import { Sportisimo_Core as Core } from '@sportisimo/core/src/Core';
import { Sportisimo_ICore as ICore } from '@sportisimo/core/src/ICore';
import { Sportisimo_IElement as IElement } from '@sportisimo/core/src/IElement';
import { Sportisimo_Element as Element } from '@sportisimo/core/src/Element';
import { Sportisimo_ILog as ILog } from '@sportisimo/core/src/ILog';
import { Sportisimo_Log as Log } from '@sportisimo/core/src/Log';
import { Sportisimo_IWindow as IWindow } from '@sportisimo/core/src/IWindow';
import { Sportisimo_Window as Window } from '@sportisimo/core/src/Window';

/**
 * Import TS core services
 */
import { Sportisimo_Model_Services_LogService as LogService } from '@sportisimo/core/src/Model/Services/LogService';
import { Sportisimo_Model_Services_NoLogService as NoLogService } from '@sportisimo/core/src/Model/Services/NoLogService';
import { Sportisimo_Model_Services_ElementService as ElementService } from '@sportisimo/core/src/Model/Services/ElementService';
import { Sportisimo_Model_Services_FetchService as FetchService } from '@sportisimo/core/src/Model/Services/FetchService';
import { Sportisimo_Model_Services_XhrService as XhrService } from '@sportisimo/core/src/Model/Services/XhrService';
import { Sportisimo_Model_Services_InputElementService as InputElementService } from '@sportisimo/core/src/Model/Services/InputElementService';
import { Sportisimo_Model_Services_SelectElementService as SelectElementService } from '@sportisimo/core/src/Model/Services/SelectElementService';
import { Sportisimo_Model_Services_TextAreaElementService as TextAreaElementService } from '@sportisimo/core/src/Model/Services/TextAreaElementService';
import { Sportisimo_Model_Services_ButtonElementService as ButtonElementService } from '@sportisimo/core/src/Model/Services/ButtonElementService';
import { Sportisimo_Model_Services_ScriptElementService as ScriptElementService } from '@sportisimo/core/src/Model/Services/ScriptElementService';
import { Sportisimo_Model_Services_FormElementService as FormElementService } from '@sportisimo/core/src/Model/Services/FormElementService';
import { Sportisimo_Model_Services_IFrameElementService as IFrameElementService } from '@sportisimo/core/src/Model/Services/IFrameElementService';
import { Sportisimo_Model_Services_ProgressElementService as ProgressElementService } from '@sportisimo/core/src/Model/Services/ProgressElementService';
import { Sportisimo_Model_Services_WindowService as WindowService } from '@sportisimo/core/src/Model/Services/WindowService';
import { Sportisimo_Model_Services_ValueElementService as ValueElementService } from '@sportisimo/core/src/Model/Services/ValueElementService';
import { Sportisimo_Model_Services_NameElementService as NameElementService } from '@sportisimo/core/src/Model/Services/NameElementService';
import { Sportisimo_Model_Services_SrcElementService as SrcElementService } from '@sportisimo/core/src/Model/Services/SrcElementService';
import { Sportisimo_Model_Services_FormItemElementService as FormItemElementService } from '@sportisimo/core/src/Model/Services/FormItemElementService';
import { Sportisimo_Model_Services_DirectionDataService as DirectionDataService } from '@sportisimo/core/src/Model/Services/DirectionDataService';
import { Sportisimo_Model_Services_DimensionDataService as DimensionDataService } from '@sportisimo/core/src/Model/Services/DimensionDataService';
import { Sportisimo_Model_Services_UrlDataService as UrlDataService } from '@sportisimo/core/src/Model/Services/UrlDataService';
import { Sportisimo_Model_Services_PositionDataService as PositionDataService } from '@sportisimo/core/src/Model/Services/PositionDataService';

/**
 * Import TS services
 */
import { Sportisimo_Button_Model_Services_ButtonDataService as ButtonDataService } from '@sportisimo/button/src/Model/Services/ButtonDataService';
import { Sportisimo_Loading_Model_Services_LoadingDataService as LoadingDataService } from '@sportisimo/loading/src/Model/Services/LoadingDataService';
import { Sportisimo_Overlay_Model_Services_OverlayDataService as OverlayDataService } from '@sportisimo/overlay/src/Model/Services/OverlayDataService';
import { Sportisimo_Sound_Model_Services_SoundDataService as SoundDataService } from '@sportisimo/sound/src/Model/Services/SoundDataService';
import { Sportisimo_Touch_Model_Services_TouchDataService as TouchDataService } from '@sportisimo/touch/src/Model/Services/TouchDataService';
import { Sportisimo_Trigger_Model_Services_TriggerDataService as TriggerDataService } from '@sportisimo/trigger/src/Model/Services/TriggerDataService';
import { Sportisimo_Target_Model_Services_TargetDataService as TargetDataService } from '@sportisimo/target/src/Model/Services/TargetDataService';
import { Sportisimo_Popup_Model_Services_PopupDataService as PopupDataService } from '@sportisimo/popup/src/Model/Services/PopupDataService';
import { Sportisimo_Zoe_Model_Services_ZoeAddToCartService as ZoeAddToCartService } from '@sportisimo/zoe/src/Model/Services/ZoeAddToCartService';
import { Sportisimo_Zoe_Model_Services_ZoeAddToCartDataService as ZoeAddToCartDataService } from '@sportisimo/zoe/src/Model/Services/ZoeAddToCartDataService';
import { Sportisimo_Zoe_Model_Services_ZoeCartContentService as ZoeCartContentService } from '@sportisimo/zoe/src/Model/Services/ZoeCartContentService';
import { Sportisimo_Zoe_Model_Services_ZoeCartContentDataService as ZoeCartContentDataService } from '@sportisimo/zoe/src/Model/Services/ZoeCartContentDataService';
import { Sportisimo_Zoe_Model_Services_ZoeCartContentItemDataService as ZoeCartContentItemDataService } from '@sportisimo/zoe/src/Model/Services/ZoeCartContentItemDataService';
import { Sportisimo_Collection_Model_Services_CollectionService as CollectionService } from '@sportisimo/collection/src/Model/Services/CollectionService';
import { Sportisimo_Collection_Model_Services_CollectionDataService as CollectionDataService } from '@sportisimo/collection/src/Model/Services/CollectionDataService';
import { Sportisimo_Collection_Model_Services_SearchFilterDataService as SearchFilterDataService } from '@sportisimo/collection/src/Model/Services/SearchFilterDataService';
import { Sportisimo_Collection_Model_Services_SearchFilterService as SearchFilterService } from '@sportisimo/collection/src/Model/Services/SearchFilterService';
import { Sportisimo_Collection_Model_Services_QuickSearchFilterDataService as QuickSearchFilterDataService } from '@sportisimo/collection/src/Model/Services/QuickSearchFilterDataService';
import { Sportisimo_Collection_Model_Services_QuickSearchFilterService as QuickSearchFilterService } from '@sportisimo/collection/src/Model/Services/QuickSearchFilterService';
import { Sportisimo_Collection_Model_Services_ItemDataService as ItemDataService } from '@sportisimo/collection/src/Model/Services/ItemDataService';
import { Sportisimo_Collection_Model_Services_ItemService as ItemService } from '@sportisimo/collection/src/Model/Services/ItemService';
import { Sportisimo_Collection_Model_Services_ItemGroupDataService as ItemGroupDataService } from '@sportisimo/collection/src/Model/Services/ItemGroupDataService';
import { Sportisimo_Collection_Model_Services_ItemGroupService as ItemGroupService } from '@sportisimo/collection/src/Model/Services/ItemGroupService';
import { Sportisimo_Collection_Model_Services_RangeService as RangeService } from '@sportisimo/collection/src/Model/Services/RangeService';
import { Sportisimo_ScrollNavigation_Model_Services_BaseDataService as ScrollNavigationBaseDataService } from '@sportisimo/scroll-navigation/src/Model/Services/BaseDataService';
import { Sportisimo_ScrollNavigation_Model_Services_NavigationItemDataService as ScrollNavigationItemDataService } from '@sportisimo/scroll-navigation/src/Model/Services/NavigationItemDataService';
import { Sportisimo_ScrollNavigation_Model_Services_ScrollNavigationService as ScrollNavigationService } from '@sportisimo/scroll-navigation/src/Model/Services/ScrollNavigationService';
import { Sportisimo_HistoryNavigation_Model_Services_BaseDataService as HistoryNavigationBaseDataService } from '@sportisimo/history-navigation/src/Model/Services/BaseDataService';

/**
 * Import TS factories
 */
import { Sportisimo_Loading_Model_Factories_LoadingFactory as LoadingFactory } from '@sportisimo/loading/src/Model/Factories/LoadingFactory';
import { Sportisimo_Overlay_Model_Factories_OverlayFactory as OverlayFactory } from '@sportisimo/overlay/src/Model/Factories/OverlayFactory';
import { Sportisimo_Sound_Model_Factories_SoundFactory as SoundFactory } from '@sportisimo/sound/src/Model/Factories/SoundFactory';
import { Sportisimo_Trigger_Model_Factories_TriggerFactory as TriggerFactory } from '@sportisimo/trigger/src/Model/Factories/TriggerFactory';
import { Sportisimo_Target_Model_Factories_TargetFactory as TargetFactory } from '@sportisimo/target/src/Model/Factories/TargetFactory';

/**
 * Import TS knihoven
 */
import { Sportisimo_Button_Button as Button } from '@sportisimo/button/src/Button';
import { Sportisimo_Collection_Collection as Collection } from '@sportisimo/collection/src/Collection';
import { Sportisimo_HistoryNavigation as HistoryNavigation } from '@sportisimo/history-navigation/src/HistoryNavigation';
import { Sportisimo_Popup_Popup as Popup } from '@sportisimo/popup/src/Popup';
import { Sportisimo_ScrollNavigation_ScrollNavigation as ScrollNavigation } from '@sportisimo/scroll-navigation/src/ScrollNavigation';
import { Sportisimo_Zoe_Zoe as Zoe } from '@sportisimo/zoe/src/Zoe';

/**
 * Inicializacia TS core services
 * Pokud je v localStorage nastaveno sm_log_enabled na '1', tak se inicializuje LogService, inak se inicializuje NoLogService.
 * Je to pomocna servisa na logovanie informacii do konzoly prehliadaca.
 */
const logEnabled: string | null = localStorage.getItem('sm_log_enabled');
const logService: LogService | NoLogService = (logEnabled && logEnabled === '1') ? new LogService(true, true, true, true) : new NoLogService();
const windowService: WindowService = new WindowService(window);
const fetchService: FetchService = new FetchService();
const xhrService: XhrService = new XhrService();
const elementService: ElementService = new ElementService(windowService);
const valueElementService: ValueElementService = new ValueElementService();
const nameElementService: NameElementService = new NameElementService();
const srcElementService: SrcElementService = new SrcElementService();
const inputElementService: InputElementService = new InputElementService();
const selectElementService: SelectElementService = new SelectElementService();
const textAreaElementService: TextAreaElementService = new TextAreaElementService();
const buttonElementService: ButtonElementService = new ButtonElementService();
const formElementService: FormElementService = new FormElementService();
const formItemElementService: FormItemElementService = new FormItemElementService();
const scriptElementService: ScriptElementService = new ScriptElementService();
const iframeElementService: IFrameElementService = new IFrameElementService();
const progressElementService: ProgressElementService = new ProgressElementService();
const directionDataService: DirectionDataService = new DirectionDataService(logService);
const dimensionDataService: DimensionDataService = new DimensionDataService(logService);
const urlDataService: UrlDataService = new UrlDataService(logService);
const positionDataService: PositionDataService = new PositionDataService(logService, dimensionDataService);

/**
 * Inicializacia TS facades
 */
const core: ICore = new Core(
  logService,
  fetchService,
  xhrService,
  elementService,
  inputElementService,
  selectElementService,
  textAreaElementService,
  buttonElementService,
  formElementService,
  formItemElementService,
  scriptElementService,
  iframeElementService,
  progressElementService
);
const coreElement: IElement = new Element(
  logService,
  elementService,
  valueElementService,
  nameElementService,
  srcElementService,
  inputElementService,
  selectElementService,
  textAreaElementService,
  buttonElementService,
  formElementService,
  formItemElementService,
  scriptElementService,
  iframeElementService,
  progressElementService
);
const coreLog: ILog = new Log(logService);
const coreWindow: IWindow = new Window(logService, windowService);

/**
 * Inicializacia TS factories
 */
const loadingFactory: LoadingFactory = new LoadingFactory(logService);
const overlayFactory: OverlayFactory = new OverlayFactory(core, coreElement, coreLog);
const soundFactory: SoundFactory = new SoundFactory(logService);
const triggerFactory: TriggerFactory = new TriggerFactory(logService);
const targetFactory: TargetFactory = new TargetFactory(logService, loadingFactory, overlayFactory);

/**
 * Inicializacia TS services
 */
const loadingDataService: LoadingDataService = new LoadingDataService(logService);
const overlayDataService: OverlayDataService = new OverlayDataService(logService);
const soundDataService: SoundDataService = new SoundDataService(logService);
const triggerDataService: TriggerDataService = new TriggerDataService(logService);
const touchDataService: TouchDataService = new TouchDataService(logService, directionDataService);
const targetDataService: TargetDataService = new TargetDataService(logService, loadingDataService, overlayDataService);
const buttonDataService: ButtonDataService = new ButtonDataService(logService, triggerDataService, soundDataService, loadingDataService, overlayDataService, touchDataService);
const zoeCartContentItemDataService: ZoeCartContentItemDataService = new ZoeCartContentItemDataService(logService);
const zoeCartContentDataService: ZoeCartContentDataService = new ZoeCartContentDataService(logService, zoeCartContentItemDataService);
const zoeAddToCartDataService: ZoeAddToCartDataService = new ZoeAddToCartDataService(logService, urlDataService);
const zoeCartContentService: ZoeCartContentService = new ZoeCartContentService(logService, zoeCartContentDataService);
const zoeAddToCartService: ZoeAddToCartService = new ZoeAddToCartService(logService, zoeAddToCartDataService);
const collectionDataService: CollectionDataService = new CollectionDataService(logService, triggerDataService, loadingDataService);
const searchFilterDataService: SearchFilterDataService = new SearchFilterDataService(logService);
const quickSearchFilterDataService: QuickSearchFilterDataService = new QuickSearchFilterDataService(logService);
const itemDataService: ItemDataService = new ItemDataService(logService);
const itemGroupDataService: ItemGroupDataService = new ItemGroupDataService(logService);
const itemService: ItemService = new ItemService(logService, itemDataService);
const itemGroupService: ItemGroupService = new ItemGroupService(logService, itemGroupDataService);
const rangeService: RangeService = new RangeService(logService);
const collectionService: CollectionService = new CollectionService(
  logService,
  triggerFactory,
  loadingFactory,
  collectionDataService,
  itemService,
  itemGroupService,
  rangeService
);
const quickSearchFilterService: QuickSearchFilterService = new QuickSearchFilterService(
  logService,
  quickSearchFilterDataService,
  collectionService,
  itemService
);
const searchFilterService: SearchFilterService = new SearchFilterService(
  logService,
  searchFilterDataService,
  collectionService,
  itemService
);
const popupDataService: PopupDataService = new PopupDataService(
  logService,
  urlDataService,
  positionDataService,
  targetDataService,
  triggerDataService,
  overlayDataService,
  touchDataService
);
const scrollNavigationItemDataService: ScrollNavigationItemDataService = new ScrollNavigationItemDataService(
  logService,
  touchDataService
);
const scrollNavigationBaseDataService: ScrollNavigationBaseDataService = new ScrollNavigationBaseDataService(
  logService,
  scrollNavigationItemDataService
);
const scrollNavigationService: ScrollNavigationService = new ScrollNavigationService(
  logService,
  scrollNavigationBaseDataService
);
const historyNavigationBaseDataService: HistoryNavigationBaseDataService = new HistoryNavigationBaseDataService(
  logService,
  urlDataService
);

/**
 * Zadefinovanie sm objektu do window
 */
declare global
{
  interface Window
  {
    sm: any
  }
}

const sm: any = window.sm;

/**
 * Zadefinovanie JS knihoven do window.sm objektu
 */
autocomplete(sm, core, coreElement, coreLog, coreWindow);
anchor(sm, core, coreElement, coreLog);
applepay(sm, core, coreElement, coreLog);
banner(sm, core, coreElement, coreLog);
bg(sm, core, coreElement, coreLog);
checkbox(sm, core, coreElement, coreLog);
click(sm, core, coreElement, coreLog, coreWindow);
countdown(sm, core, coreElement, coreLog);
form(sm, core, coreElement, coreLog, coreWindow);
geolocate(sm, core, coreElement, coreLog);
gtm(sm, core, coreElement, coreLog);
height(sm, core, coreElement, coreLog, coreWindow);
input(sm, core, coreElement, coreLog, coreWindow);
image(sm, core, coreElement, coreLog, coreWindow);
imagerotator(sm, core, coreElement, coreLog);
link(sm, core, coreElement, coreLog, coreWindow);
loading(sm, core, coreElement, coreLog);
menu(sm, core, coreElement, coreLog);
overlay(sm, core, coreElement, coreLog);
productlist(sm, core, coreElement, coreLog, coreWindow);
robotcheck(sm, core, coreElement, coreLog);
rwd(sm, core, coreElement, coreLog, coreWindow);
scroll(sm, core, coreElement, coreLog, coreWindow);
serviceworker(sm);
share(sm, core, coreElement, coreLog);
slider(sm, core, coreElement, coreLog, coreWindow);
sound(sm);
touch(sm, core, coreElement);
upload(sm, core, coreElement, coreLog, coreWindow);
url(sm);
valueplusminus(sm, core, coreElement, coreLog);
valueselect(sm, core, coreElement, coreLog);
valueslider(sm, core, coreElement, coreLog);
valuetoggle(sm, core, coreElement, coreLog);
viewport(sm, core, coreLog);
yvideo(sm, core, coreElement, coreLog);
recombee(sm, core, coreElement, coreLog);

/**
 * Inicializacia JS knihoven
 */
sm.rwd.init();
sm.height.init();
sm.bg.init();
sm.image.init();
sm.banner.init();
sm.slider.init();
sm.menu.init();
sm.anchor.init();
sm.scroll.init();
sm.click.init();
sm.form.init();
sm.link.init();

sm.autocomplete.init();
sm.input.init();
sm.valueselect.init();
sm.valueslider.init();
sm.valuetoggle.init();
sm.valueplusminus.init();
sm.checkbox.init();
sm.productlist.init();
sm.countdown.init();
sm.geolocate.init();
sm.upload.init();
sm.share.init();
sm.applepay.init();
sm.imagerotator.init();
sm.yvideo.init();
sm.recombee.init();

/**
 * Inicializacia TS knihoven
 */
sm.button = new Button(
  logService,
  buttonDataService,
  loadingFactory,
  overlayFactory,
  soundFactory,
  triggerFactory,
  sm.touch ? sm.touch : null
);
sm.button.init();

sm.historyNavigation = new HistoryNavigation(coreLog, coreWindow, historyNavigationBaseDataService);
sm.historyNavigation.init();

sm.popup = new Popup(
  core,
  coreElement,
  coreLog,
  coreWindow,
  popupDataService,
  targetDataService,
  targetFactory,
  triggerFactory,
  overlayFactory,
  sm.rwd ? sm.rwd : null,
  sm.touch ? sm.touch : null
);
sm.popup.init();

sm.zoe = new Zoe(zoeAddToCartService, zoeCartContentService);
sm.zoe.init();

sm.collection = new Collection(
  collectionService,
  searchFilterService,
  quickSearchFilterService,
  itemService,
  itemGroupService
);
sm.collection.init();

sm.scrollNavigation = new ScrollNavigation(scrollNavigationService, sm.touch ? sm.touch : null);
sm.scrollNavigation.init();

/**
 * Inicializacia service workera
 */
if(sm.initData && sm.initData.serviceWorkerInit)
{
  sm.serviceworker.init();
}

/**
 * Inicializacia GTM
 */
if(sm.initData && sm.initData.gtmInit)
{
  if(sm.initData.gtmSetId)
  {
    sm.gtm.setId(sm.initData.gtmSetId);
  }
  sm.gtm.init();
}

/**
 * Inicializacia scriptov na evente window load
 * Vykona sa ked je nacitana cela stranka, vratane CSS, obrazkov a ostatnych assetov
 */
window.addEventListener('load', () =>
{
  sm.robotcheck.init();
});

/**
 * Zadefinovanie typu globalnej konstatny, pre vlozenie informacii o verziach pouzitych
 * modulov pomocou webpack.DefinePlugin, a vlozenie tychto dat do sm objektu
 */
declare const __MODULES_INFO__: any;
sm._modules = __MODULES_INFO__;
