import 'intersection-observer';

// Doplnenie intersectionRatio do prototypu IntersectionObserverEntry pretoze ho tam polyfill nepridava a v
// js_old_viewport kniznici, mame kontrolu 'intersectionRatio' in window.IntersectionObserverEntry.prototype;
if(!('intersectionRatio' in window.IntersectionObserverEntry.prototype))
{
  Object.defineProperty(IntersectionObserverEntry.prototype, 'intersectionRatio', {
    get() {
      return this._intersectionRatio;
    },
    set(value) {
      this._intersectionRatio = value;
    },
    configurable: true,
    enumerable: true
  });
}